<template>
  <div class="login__container">
    <h2>Chatter Login</h2>
    <p>To log into your account, click the button below to sign in using your Google Account.</p>
    <a href="/login/google" class="google-login-btn" @click="saveRedirectQuery">
      <inline-svg :src="require('@/assets/btn_google_light_normal_ios.svg')" width="46" height="46"/>
      <span class="ml-2">Sign in with Google</span>
    </a>
  </div>
</template>

<script>
export default {
  methods: {
    saveRedirectQuery() {
      const { redirect } = this.$route.query;
      if (typeof redirect !== 'undefined') {
        localStorage.setItem('redirect', redirect);
      } else {
        localStorage.removeItem('redirect');
      }
    }
  },
}
</script>